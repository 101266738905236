<template>
  <div class="home-spain">
    <div>
      <BenefitsCard
        class="only-desktop-block"
        :tags="['GIVINGS', 'BEST_PRICE', 'SAFE_PAYMENT']"
        :can-close="false"
        :is-title-displayed="false"
      />
    </div>

    <div class="home-spain__container">
      <div class="only-mobile home-spain__main-title">
        <Lang by-key="home-header-title" />
      </div>
    </div>

    <div class="home-spain__container__small">
      <Card
        class="home-spain__highlight-display"
        mobile-with-border
        desktop-with-border
      >
        <div class="home-spain__highlight-display__side-texts">
          <p class="title">
            <Lang by-key="home-promo-title" />
          </p>

          <p class="subtitle">
            <Lang by-key="home-promo-subtitle" />
          </p>

          <div>
            <p>
              <Lang by-key="home-promo-extra-1" />
            </p>
          </div>

          <div class="base-price">
            <span>
              <Lang by-key="from-value" />
            </span>
            <span class="base-price__price">28 €</span>
          </div>

          <div class="button">
            <SplNuxtLink
              is-external
              to="/promociones/daypass-spa/"
            >
              <SplButton
                variant="acent"
                size="full"
              >
                <Lang
                  by-key="home-promo-btn-label"
                  capitalize-first
                />
              </SplButton>
            </SplNuxtLink>
          </div>
        </div>

        <div>
          <Card
            class="home-spain__highlight-display__side-details"
            mobile-with-border
            desktop-with-border
          >
            <div class="image">
              <img src="~/assets/images/slider/Slide2.webp">
            </div>

            <p class="title">
              <Lang by-key="home-promo-side-title" />
            </p>

            <div class="subtitle">
              <Lang by-key="home-promo-side-subtitle" />
            </div>

            <div class="base-price">
              <span>
                <Lang by-key="from-value" />
              </span>
              <span class="base-price__price">28 €</span>
            </div>
          </Card>
        </div>
      </Card>
    </div>

    <div class="home-spain__container">
      <BenefitsCard
        class="only-mobile"
        :tags="['GIVINGS-SMALL', 'BEST_PRICE', 'SAFE_PAYMENT']"
        :can-close="false"
        :is-title-displayed="false"
      />
    </div>

    <div class="home-spain__container home-spain__header-and-text">
      <div>
        <h2><Lang by-key="home-promo-section-1-title" /></h2>

        <p>
          <Lang by-key="home-promo-section-1-text" />
        </p>
      </div>
    </div>

    <div class="home-spain__container only-mobile">
      <SlideShow
        uuid="home-spain-slider"
        :elements="promoLinksA"
        display-controls
      >
        <template #default="{ element }">
          <SplNuxtLink
            :to="element.url"
            is-external
          >
            <div class="home-spain__promo-item">
              <div>
                <div v-if="element.image">
                  <img
                    class="home-spain__promo-item__image"
                    :src="element.image"
                  >
                </div>

                <h3 class="home-spain__promo-item__title">
                  {{ element.title }}
                </h3>
              </div>

              <div class="home-spain__promo-item__description">
                {{ element.description }}
              </div>

              <SplButton
                variant="acent"
                size="full"
              >
                <Lang
                  by-key="see-more"
                  capitalize-first
                />
              </SplButton>
            </div>
          </SplNuxtLink>
        </template>
      </SlideShow>
    </div>

    <div class="home-spain__container only-desktop-block">
      <div class="home-spain__list-display-5">
        <div
          v-for="promo in promoLinksA"
          :key="promo.url"
          class="home-spain__list-display-5__item"
        >
          <SplNuxtLink
            :to="promo.url"
            is-external
          >
            <div class="home-spain__promo-item">
              <div>
                <div v-if="promo.image">
                  <img
                    class="home-spain__promo-item__image"
                    :src="promo.image"
                  >
                </div>

                <h3 class="home-spain__promo-item__title">
                  {{ promo.title }}
                </h3>
              </div>

              <div class="home-spain__promo-item__description">
                {{ promo.description }}
              </div>

              <SplButton
                variant="acent"
                size="full"
              >
                <Lang
                  by-key="see-more"
                  capitalize-first
                />
              </SplButton>
            </div>
          </SplNuxtLink>
        </div>
      </div>
    </div>

    <div class="home-spain__container home-spain__header-and-text">
      <div>
        <h2><Lang by-key="home-promo-section-2-title" /></h2>

        <p>
          <Lang by-key="home-promo-section-2-text" />
        </p>
      </div>
    </div>

    <div class="home-spain__container only-mobile">
      <SlideShow
        uuid="home-spain-slider"
        :elements="treatmentListA"
        display-controls
      >
        <template #default="{ element }">
          <SplNuxtLink
            :to="element.url"
            is-external
          >
            <div class="home-spain__promo-item">
              <div>
                <div v-if="element.image">
                  <img
                    class="home-spain__promo-item__image"
                    :src="element.image"
                  >
                </div>

                <h3 class="home-spain__promo-item__title">
                  {{ element.title }}
                </h3>
              </div>

              <div class="home-spain__promo-item__description">
                {{ element.description }}
              </div>

              <SplButton
                variant="acent"
                size="full"
              >
                <Lang
                  by-key="see-more"
                  capitalize-first
                />
              </SplButton>
            </div>
          </SplNuxtLink>
        </template>
      </SlideShow>
    </div>

    <div class="home-spain__container only-desktop-block">
      <div class="home-spain__list-display-5">
        <div
          v-for="treatment in treatmentListA"
          :key="treatment.url"
          class="home-spain__list-display-5__item"
        >
          <SplNuxtLink
            :to="treatment.url"
            is-external
          >
            <div class="home-spain__promo-item">
              <div>
                <div v-if="treatment.image">
                  <img
                    class="home-spain__promo-item__image"
                    :src="treatment.image"
                  >
                </div>

                <h3 class="home-spain__promo-item__title">
                  {{ treatment.title }}
                </h3>
              </div>

              <div class="home-spain__promo-item__description">
                {{ treatment.description }}
              </div>

              <SplButton
                variant="acent"
                size="full"
              >
                <Lang
                  by-key="see-more"
                  capitalize-first
                />
              </SplButton>
            </div>
          </SplNuxtLink>
        </div>
      </div>
    </div>

    <div class="home-spain__container home-spain__header-and-text">
      <div>
        <h2><Lang by-key="home-promo-section-3-title" /></h2>

        <p>
          <Lang by-key="home-promo-section-3-text" />
        </p>
      </div>
    </div>

    <div class="home-spain__container only-mobile">
      <SlideShow
        uuid="home-spain-slider"
        :elements="promoLinksB"
        display-controls
      >
        <template #default="{ element }">
          <SplNuxtLink
            :to="element.url"
            is-external
          >
            <div class="home-spain__promo-item">
              <div>
                <div v-if="element.image">
                  <img
                    class="home-spain__promo-item__image"
                    :src="element.image"
                  >
                </div>

                <h3 class="home-spain__promo-item__title">
                  {{ element.title }}
                </h3>
              </div>

              <div class="home-spain__promo-item__description">
                {{ element.description }}
              </div>

              <SplButton
                variant="acent"
                size="full"
              >
                <Lang
                  by-key="see-more"
                  capitalize-first
                />
              </SplButton>
            </div>
          </SplNuxtLink>
        </template>
      </SlideShow>
    </div>

    <div class="home-spain__container only-desktop-block">
      <div class="home-spain__list-display-5">
        <div
          v-for="promo in promoLinksB"
          :key="promo.url"
          class="home-spain__list-display-5__item"
        >
          <SplNuxtLink
            :to="promo.url"
            is-external
          >
            <div class="home-spain__promo-item">
              <div>
                <div v-if="promo.image">
                  <img
                    class="home-spain__promo-item__image"
                    :src="promo.image"
                  >
                </div>

                <h3 class="home-spain__promo-item__title">
                  {{ promo.title }}
                </h3>
              </div>

              <div class="home-spain__promo-item__description">
                {{ promo.description }}
              </div>

              <SplButton
                variant="acent"
                size="full"
              >
                <Lang
                  by-key="see-more"
                  capitalize-first
                />
              </SplButton>
            </div>
          </SplNuxtLink>
        </div>
      </div>
    </div>

    <div class="only-desktop-block">
      <Separator hide />
      <BenefitsCard
        :tags="['VALID_BONUSES', 'SUPPORT', 'GIVE_EXPERIENCIES']"
        :can-close="false"
        :is-title-displayed="false"
      />
      <Separator hide />
    </div>

    <div class="home-spain__container home-spain__header-and-text">
      <div>
        <h2><Lang by-key="home-promo-section-4-title" /></h2>

        <p>
          <Lang by-key="home-promo-section-4-text" />
        </p>
      </div>
    </div>

    <div
      v-if="shouldDisplayESLocs"
      class="home-spain__container"
    >
      <div class="home-spain__location-title">
        España
      </div>

      <div class="home-spain__list-display-4">
        <div
          v-for="location in esLocationLinks"
          :key="location.url"
          class="home-spain__list-display-4__item"
        >
          <SplNuxtLink
            :to="location.url"
            is-external
          >
            <div class="home-spain__promo-item">
              <div class="home-spain__promo-item__image-wrapper">
                <img :src="location.image">
              </div>

              <SplButton
                variant="acent"
                size="full"
              >
                {{ location.title }}
              </SplButton>
            </div>
          </SplNuxtLink>
        </div>
      </div>
    </div>

    <div
      v-if="shouldDisplayPTLocs"
      class="home-spain__container"
    >
      <div class="home-spain__location-title">
        Portugal
      </div>

      <div class="home-spain__list-display-4">
        <div
          v-for="location in ptLocationLinks"
          :key="location.url"
          class="home-spain__list-display-4__item"
        >
          <SplNuxtLink
            :to="location.url"
            is-external
          >
            <div class="home-spain__promo-item">
              <div class="home-spain__promo-item__image-wrapper">
                <img :src="location.image">
              </div>

              <SplButton
                variant="acent"
                size="full"
              >
                {{ location.title }}
              </SplButton>
            </div>
          </SplNuxtLink>
        </div>
      </div>
    </div>

    <Separator hide />

    <div class="home-spain__highlight">
      <div class="home-spain__container">
        <GiftCard />
      </div>
    </div>

    <Separator hide />
    <div
      v-if="isDev"
      class="home-spain__container__small"
    >
      <NewsLetterSub />
    </div>
    <Separator
      v-if="isDev"
      hide
    />
  </div>
</template>

<script lang="ts">
import BenefitsCard from '~/core/components/spa/BenefitsCard.vue'
import GiftCard from '~/core/components/home/GiftCard.vue'
import NewsLetterSub from '~/core/components/home/NewsLetterSub.vue'

import Separator from '~/core/components/shared/Separator.vue'
import Card from '~/core/components/shared/Card.vue'
import Badge from '~/core/components/shared/Badge.vue'
import SplNuxtLink from '~/core/components/shared/SplNuxtLink.vue'
import SplButton from '~/core/components/shared/form/SplButton.vue'
import SlideShow from '~/core/components/shared/gallery/SlideShow.vue'

import { getHomeMetadata } from '~/core/composable/home/useHomePageHeader'
import { useSessionLocation } from '~/core/composable/shared/useSessionLocation'

definePageMeta({
  layout: 'home',
})

export default defineNuxtComponent({
  components: {
    BenefitsCard,
    GiftCard,
    NewsLetterSub,
    Separator,
    Card,
    Badge,
    SplNuxtLink,
    SplButton,
    SlideShow,
  },
  setup() {
    const route = useRoute()
    const uri = route.path
    useHead(getHomeMetadata(uri))

    const config = useRuntimeConfig()
    const isDev = config.public.isDev

    const sessionLocation = useSessionLocation()

    const promoLinksA = ([
      {
        title: 'Spa',
        image: 'https://www.spalopia.com/images/home/promos/spa.jpg',
        description: 'Relájate con circuito termal',
        url: '/promociones/circuito/',
      },
      {
        title: 'Spa + Gastro',
        image: 'https://www.spalopia.com/images/home/promos/spa-gastro.jpg',
        description: 'Alimenta tu espítitu',
        url: '/promociones/circuito-spa-comida/',
      },
      {
        title: 'Spa + Masaje',
        image: 'https://www.spalopia.com/images/home/promos/spa-masaje.jpg',
        description: 'Descanso y relax total',
        url: '/promociones/circuito-y-masaje/',
      },
      {
        title: 'Day Spa',
        image: 'https://www.spalopia.com/images/home/promos/Daypass.png',
        description: 'Placer a día completo',
        url: '/promociones/daypass-spa/',
      },
      {
        title: 'Escapada Wellness',
        image: 'https://www.spalopia.com/images/home/promos/spa-alojamiento.jpg',
        description: 'Relax full-time Spa + Hotel',
        url: '/escapadas-hotel-spa/',
      }
    ])

    const treatmentListA = [
      {
        title: 'Hidroterapia',
        url: '/hidroterapia/',
        image: 'https://www.spalopia.com/images/home/treatments/Hidroterapia-min.png',
        description: 'Disfruta de un baño relajante',
      },
      {
        title: 'Masajes',
        url: '/masajes/',
        image: 'https://www.spalopia.com/images/home/treatments/Masajes-min.png',
        description: 'Libera tensiones',
      },
      {
        title: 'Faciales',
        url: '/tratamientos-faciales/',
        image: 'https://www.spalopia.com/images/home/treatments/Tratamientos-Faciales-min.png',
        description: 'Oxigena la cara',
      },
      {
        title: 'Corporales',
        url: '/tratamientos-corporales/',
        image: 'https://www.spalopia.com/images/home/treatments/Tratamientos-Corporales-min.png',
        description: 'Dale un nuevo brillo a tu piel',
      },
      {
        title: 'Rituales',
        url: '/rituales-belleza/',
        image: 'https://www.spalopia.com/images/home/treatments/Rituales-de-Belleza-min.png',
        description: 'Te mereces desconectar',
      },
    ]

    const promoLinksB = ([
      {
        title: 'Spa en Pareja',
        image: 'https://www.spalopia.com/images/home/promos/spa-pareja.jpg',
        description: 'Un momento único para dos',
        url: '/promociones/spa-en-pareja/',
      },
      {
        title: 'Spa Embarazadas',
        image: 'https://www.spalopia.com/images/home/promos/spa-embarazada.jpg',
        description: 'Alivia tensiones y cansancio',
        url: '/promociones/spa-para-embarazadas/',
      },
      {
        title: 'Spa para Niños',
        image: 'https://www.spalopia.com/images/home/promos/spa-ni%C3%B1os.jpg',
        description: 'Descanso bien merecido',
        url: '/promociones/spa-para-ninos/',
      },
      {
        title: 'Spa Privado',
        image: 'https://www.spalopia.com/images/home/promos/spa-privado.jpg',
        description: 'Paz de harmonía en pareja',
        url: '/promociones/spa-privado/',
      },
      {
        title: 'Masaje para dos',
        image: 'https://www.spalopia.com/images/home/promos/masaje-dos.jpg',
        description: 'Relaja tu cuerpo en compañía',
        url: '/promociones/masaje-para-dos/',
      }
    ])

    const esLocationLinks = ([
      {
        url: '/spa-balneario/tenerife/',
        image: 'https://www.spalopia.com/images/home/locations/tenerife.jpg',
        title: 'Tenerife',
      },

      {
        url: '/spa-balneario/gran-canaria/',
        image: 'https://www.spalopia.com/images/home/locations/gran-canaria.jpg',
        title: 'Gran Canaria',
      },

      {
        url: '/spa-balneario/granada/',
        image: 'https://www.spalopia.com/images/home/locations/granada.jpg',
        title: 'Granada',
      },

      {
        url: '/spa-balneario/cadiz/',
        image: 'https://www.spalopia.com/images/home/locations/cadiz.jpg',
        title: 'Cádiz',
      },

      {
        url: '/spa-balneario/mallorca/',
        image: 'https://www.spalopia.com/images/home/locations/mallorca.jpg',
        title: 'Mallorca',
      },

      {
        url: '/spa-balneario/valencia/',
        image: 'https://www.spalopia.com/images/home/locations/valencia.jpg',
        title: 'Valencia',
      },

      {
        url: '/spa-balneario/madrid/',
        image: 'https://www.spalopia.com/images/home/locations/madrid.jpg',
        title: 'Madrid',
      },

      {
        url: '/spa-balneario/barcelona/',
        image: 'https://www.spalopia.com/images/home/locations/barcelona.jpg',
        title: 'Barcelona',
      },
    ])

    const ptLocationLinks = ([
      {
        url: '/escapadas-hotel-spa/lisboa/',
        image: 'https://www.spalopia.com/images/home/locations/lisboa.jpg',
        title: 'Lisboa',
      },

      {
        url: '/escapadas-hotel-spa/oporto/',
        image: 'https://www.spalopia.com/images/home/locations/oporto.jpg',
        title: 'Oporto',
      },

      {
        url: '/escapadas-hotel-spa/madeira/',
        image: 'https://www.spalopia.com/images/home/locations/madeira.jpg',
        title: 'Madeira',
      },

      {
        url: '/escapadas-hotel-spa/acores/',
        image: 'https://www.spalopia.com/images/home/locations/azores.jpg',
        title: 'Açores',
      },
    ])

    const shouldDisplayESLocs = computed(() => {
      const country = sessionLocation.storedLocationCountry.value

      if (!country) {
        return true
      }

      return country.countryCode === 'ES'
    })

    const shouldDisplayPTLocs = computed(() => {
      const country = sessionLocation.storedLocationCountry.value

      if (!country) {
        return true
      }

      return country.countryCode === 'PT'
    })

    return {
      isDev,

      promoLinksA,
      treatmentListA,
      promoLinksB,

      shouldDisplayESLocs,
      shouldDisplayPTLocs,

      esLocationLinks,
      ptLocationLinks,
    }
  },
})
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  @apply opacity-100;
  @apply transition-all duration-500;
}
.fade-enter,
.fade-leave-to {
  @apply opacity-0;
  @apply mt-4;
}

.home-spain {
  @apply py-2;

  &__main-title {
    @apply text-center;

    @apply text-xl font-semibold;
    @apply text-spl-dark;

    @apply px-4;

    @apply pt-8;
    @apply pb-6;
  }

  &__location-title {
    @apply mt-4 mb-2;

    @apply font-bold;
    @apply text-xl;

    @apply text-spl-primary-dark;
  }

  &__highlight {
    @apply bg-spl-gray-1;
  }

  &__container,
  &__container__small {
    @apply w-full;

    @apply pb-2;

    @screen md {
      // @apply w-2/3;
      @apply w-5/6;
      @apply mx-auto;
    }
  }

  &__container__small {
    @screen lg {
      @apply w-3/4;
      @apply mx-auto;
    }

    @screen 2xl {
      @apply w-2/4;
      @apply mx-auto;
    }
  }

  &__header-and-text {
    @apply mt-16;
    @apply mb-12;

    @apply px-8;

    @screen md {
      @apply px-0;
    }

    h2 {
      @apply my-4;

      @apply text-spl-dark;
      @apply text-2xl;

      @apply font-bold;
    }

    p {
      @apply mb-2;
      @apply text-xl;

      @screen md {
        @apply w-2/3;
      }

      @screen lg {
        @apply w-1/2;
      }
    }
  }

  &__highlight-display {
    @apply grid;
    @apply grid-cols-1;
    @apply grid-rows-2;
    @apply gap-5;

    @apply my-2;
    @apply p-12;

    @screen md {
      @apply my-4;
      @apply p-8;

      @apply grid-cols-2;
      @apply grid-rows-1;
    }

    @screen lg {
      @apply p-12;
    }

    background-image: url('~/assets/images/slider/Slide2.webp');
    background-size: cover;

    background-color: rgba(12,12,12,.5);
    background-blend-mode: multiply;

    &__side-texts {
      @apply text-white;

      @apply h-full;

      @apply flex;
      @apply flex-col;

      @apply justify-between;

      @apply gap-4;

      @apply py-12;

      .title {
        @apply text-4xl;
        @apply font-bold;
        @apply mb-2;
      }

      .subtitle {
        @apply text-3xl;
      }

      .base-price {
        @apply my-6;

        @apply flex;
        @apply items-center;
        @apply justify-center;
        @apply gap-2;

        @apply text-lg;

        &__price {
          @apply text-4xl;
          @apply font-bold;
        }
      }

      .button {
        @apply text-center;

        @apply px-0;
        @screen md {
          @apply px-8;
        }
      }
    }

    &__side-details {
      @apply bg-white;
      @apply h-full;

      @apply flex;
      @apply flex-col;

      @apply justify-between;

      @apply p-8;

      .image {
        img {
          @apply rounded-2xl;
          @apply w-full;
        }
      }

      .title {
        @apply font-bold;
        @apply text-spl-primary;

        @apply text-2xl;
        @apply my-1;
        @apply pt-2;
      }

      .subtitle {
        @apply text-lg;
        @apply text-spl-dark;
      }

      .base-price {
        @apply mt-4;

        @apply flex;
        @apply items-center;
        @apply justify-center;
        @apply gap-2;

        @apply text-lg;

        &__price {
          @apply text-spl-dark;

          @apply text-4xl;
          @apply font-bold;
        }
      }
    }
  }

  &__list-display-5 {
    @apply w-full;

    @apply grid;
    @apply grid-cols-1;
    @apply gap-6;

    @screen md {
      @apply gap-6;
      @apply grid-cols-2;
    }

    @screen lg {
      @apply grid-cols-5;
    }

    &__item {
      // @apply flex-1;
    }
  }

  &__list-display-4 {
    @apply w-full;

    @apply grid;
    @apply grid-cols-1;
    @apply gap-2;

    @screen md {
      @apply gap-6;
      @apply grid-cols-2;
    }

    @screen lg {
      @apply grid-cols-4;
    }

    &__item {
      // @apply flex-1;
    }
  }

  &__promo-item {
    @apply text-spl-primary-dark;

    @apply h-full;

    @apply flex;
    @apply flex-col;

    @apply justify-between;

    @apply px-6;
    @apply py-4;

    @screen md {
      @apply px-0;
      @apply py-0;
    }

    &__image {
      @apply w-full;
    }

    &__image-wrapper {
      @apply w-full;
      @apply h-200p;

      @screen md {
        @apply h-150p;
      }

      @screen lg {
        @apply h-170p;
      }

      @apply overflow-hidden;

      img {
        @apply w-full;
      }
    }

    &__title {
      @apply pt-4;

      @apply text-xl;
      @apply font-bold;
    }

    &__description {
      @apply pt-2;
      @apply pb-4;

      @apply text-lg;
    }
  }
}
</style>
